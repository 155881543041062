import { Show, Typography } from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import React from "react";

export const About: React.FC<IResourceComponentsProps> = () => {
  return (
    <Show
      headerProps={{
        title: process.env.REACT_APP_ApplicationTitle,
        extra: <></>
      }}>
      <Typography.Title level={5}>
        AI Showcase is built using TCS platform Helium AI which is used to operationalize AI models into business processes. In the current deployment you are using a proof of concept executed by the AIT team for Microsoft.
      </Typography.Title>
    </Show >
  );
};
