import { AntdLayout, Button, Icons, Space, Spin, Typography } from "@pankod/refine-antd";
import { useLogin } from "@pankod/refine-core";
import { useState } from "react";
import { HELIUM_LOGO_PATH, TCS_LOGO_PATH } from "scripts/site";

export const Login: React.FC = () => {
    const { mutate: login, isLoading } = useLogin();
    const [loginDisabled, setLoginDisabled] = useState(false);

    return (
        <AntdLayout>
            <div style={{ height: "100vh", display: "flex" }}>
                <div style={{
                    margin: "auto"
                }}>
                    <Space direction="vertical" wrap align="center" >
                        <div style={{ marginBottom: "16px" }}>
                            <img src={HELIUM_LOGO_PATH} alt={process.env.REACT_APP_ApplicationTitle} style={{ maxWidth: "200px" }} />
                        </div>

                        <Typography.Title level={4} style={{ marginBottom: "0px" }}>
                            {`Welcome to ${process.env.REACT_APP_ApplicationTitle}`}
                        </Typography.Title>

                        <div style={{ marginBottom: "16px" }}>
                            <Typography.Text  >
                                An <span style={{ color: "limegreen" }}>
                                    A
                                </span>rchitecture, <span style={{ color: "limegreen" }}>
                                    I
                                </span>nnovation and <span style={{ color: "limegreen" }}>
                                    T
                                </span>ransformation team initiative
                            </Typography.Text>
                        </div>

                        <div style={{ width: 150 }}>
                            <Spin spinning={isLoading || loginDisabled}>
                                <Button
                                    id="signin-button"
                                    block
                                    type="primary"
                                    disabled={loginDisabled}
                                    size="large"
                                    onClick={() => {
                                        setLoginDisabled(true);
                                        login({});
                                    }}
                                //className="signin-button"
                                >
                                    Sign in
                                    <Icons.LoginOutlined />
                                </Button>
                            </Spin>
                        </div>
                        <div style={{ marginBottom: "22px" }}>
                            {
                                process.env.REACT_APP_BuildId &&
                                <Typography.Text style={{ color: "GrayText" }}>Version: {process.env.REACT_APP_BuildId}</Typography.Text>
                            }
                        </div>
                        <div>
                            <img src={TCS_LOGO_PATH} alt="TCS" style={{ maxWidth: "50px" }} />
                        </div>
                    </Space>
                </div>
            </div>
        </AntdLayout>
    );
};