import { Alert, AntdList, Avatar, Button, Divider, Form, Icons, SaveButton, Select, Show, useForm } from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { chatModel } from "interfaces";
import { useState } from "react";
import { OPENAI_LOGO_PATH, OPENAI_LOGO_PATH2 } from "scripts/site";
//const { Search } = Input;

export const ResumeParserList: React.FC<IResourceComponentsProps> = () => {
  const [chatData, setChatData] = useState<chatModel[]>([]);
  const [pdfUrl, setpdfUrl] = useState("");
  const handleChange = (e: any) => {
    setChatData([])
    setpdfUrl(`./${e}.pdf`)
    console.log(`/${e}.pdf`)
  }
  const { formProps, saveButtonProps, formLoading } = useForm({
    onMutationSuccess(data, variables, context) {
      console.log(data?.data)
      setChatData([{
        actor: "GPT-4",
        conversation: {
          contextual: String(data?.data),
          standard: String(data?.data)
        },
        error: false
      }]);
    },
    onMutationError(error, variables, context) {
      setChatData([{
        actor: "GPT-4",
        conversation: {
          contextual: error?.statusCode + " - " + error?.response?.statusText + ": " + error?.response?.data?.message,
          standard: error?.statusCode + " - " + error?.response?.statusText + ": " + error?.response?.data?.message,
        },
        error: true
      }]);
    },
    redirect: false,
  });


  return (
    <Show
      canDelete={false}
      isLoading={formLoading}
      headerProps={{
        backIcon: false,
        title: "Resume Parser",
        extra: chatData.length > 0 && <Button danger icon={<Icons.ClearOutlined />} onClick={() => { setChatData([]) }}>Clear</Button>
      }}>
      <Alert message={
        <>Automatically extracting key information such as name, roles, skills, contact details et., from resumes using natural language processing (NLP). Access detailed article
          <a target="_blank" rel="noopener noreferrer" href="https://helium.innovatewithtcs.com/#/accelerators/article/show/5d098cf0-0d2b-4ab3-84ff-69985cf361b0"> here.</a>
        </>
      }
        type="info" showIcon={false} banner style={{ marginBottom: 20 }}></Alert>
      <Form {...formProps} layout="vertical" autoComplete="off">
        <Form.Item
          label="Resume Selection"
          name="resumeSelection"
          style={{ marginBottom: 40 }}
          rules={[{
            required: true,
            message: 'Please select a resume to parse!',
          }]}
        >
          <Select
            allowClear
            placeholder="Select a Resume"
            onChange={handleChange}
          //onChange={function (val) { setCountry(val); form.setFieldsValue({ 'location': null }) }}
          >
            <Select.Option value={"1"} key={"1"}>David-Delivery Head</Select.Option>
            <Select.Option value={"2"} key={"2"}>Emili-SupportOps</Select.Option>
            <Select.Option value={"3"} key={"3"}>Jessica-Project Manager</Select.Option>
            <Select.Option value={"4"} key={"4"}>John-SoftwareEngineer</Select.Option>
            <Select.Option value={"5"} key={"5"}>Michael-Architect</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <SaveButton {...saveButtonProps} title="Send" icon={<Icons.SendOutlined />}>Send</SaveButton>
        </Form.Item>
      </Form>
      {pdfUrl &&
        <object data={pdfUrl} type="application/pdf" width="100%" height="500px"><p>View <a href={pdfUrl}>PDF in another tab!</a></p></object>
      }
      {chatData.length > 0 &&
        <>
          <Divider>Parsed Data</Divider>
          <div
            id="scrollableDiv"
            style={{
              maxHeight: 500,
              overflow: 'auto',
              marginBottom: 20,
              //padding: '0 16px',
              //border: '1px solid rgba(140, 140, 140, 0.35)',
            }}
          >
            <AntdList
              itemLayout="vertical"
              pagination={false}
              dataSource={chatData}
              renderItem={(item, index) => (
                <AntdList.Item style={{ border: "none" }}>
                  <AntdList.Item.Meta
                    avatar={<Avatar src={index % 2 === 0 ? OPENAI_LOGO_PATH : OPENAI_LOGO_PATH2} />}
                    title={item.actor}
                    description={<>
                      {
                        item.conversation.standard
                          .split(/(?:\r\n|\r|\n)/g)
                          .map((text, index) => <>{text}<br /></>)
                      }

                    </>} />
                </AntdList.Item>
              )}
            >
            </AntdList>
          </div>
        </>
      }

    </Show>
  );
};