import { AntdLayout, Avatar, Button, Col, Grid, Icons, Row, Space, Spin, Tooltip, Typography } from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import { IUser } from "interfaces";
import { useEffect } from "react";
import { AAD_LOGOUT, clearLocalSessionStorage, LOGO_PATH } from "scripts/site";
import { HeliumTheme, setAppTheme } from "scripts/theme";

const { Text } = Typography;
const { useBreakpoint } = Grid;
interface HeaderProps {
    theme: HeliumTheme;
    setTheme: (theme: HeliumTheme) => void;
}

export const AppHeader: React.FC<HeaderProps> = (props) => {
    const { data, isLoading } = useGetIdentity();
    const user = data as IUser;
    const screens = useBreakpoint();
    //const { tableQueryResult } = useNotifications();

    useEffect(() => {
        if (user?.preferences?.theme !== props.theme && user?.preferences?.theme !== undefined) {
            props.setTheme(user.preferences.theme as HeliumTheme);
            setAppTheme(user.preferences.theme as HeliumTheme)
        }
    }, [user?.preferences?.theme, props, props.theme])

    return (
        <AntdLayout.Header
            style={{
                padding: "0px 24px",
            }}
        >
            <Row align="middle" justify={screens.sm ? "space-between" : "space-between"}>
                <Col xs={2} sm={2} lg={0}>
                    <Tooltip title="Reload">
                        <a href="/" onClick={() => clearLocalSessionStorage(false)}>
                            <img
                                src={LOGO_PATH}
                                alt="AI Showcase"
                                className="app-logo-header"
                            />
                        </a>
                    </Tooltip>
                </Col>
                <Col xs={4} sm={4}>
                </Col>
                <Col>
                    <Spin spinning={isLoading}>
                        <Space size="small" wrap>
                            <Tooltip color="geekblue" title={
                                <>
                                    <Text style={{ color: "white" }} strong>{user?.id}</Text>
                                    {
                                        user?.linkedUserIds?.map((r, i) => <div key={i} ><Text style={{ color: "white" }} italic>{r}</Text></div>)
                                    }
                                </>
                            }>
                                {user?.userImages?.find(img => img.uid === user?.userProfileImage)?.url &&
                                    <Avatar
                                        style={{ marginRight: 10 }}
                                        size="large"
                                        alt="Profile Image"
                                        src={user?.userImages?.find(img => img.uid === user?.userProfileImage)?.url || LOGO_PATH}
                                    />
                                }
                                <Text>
                                    {user?.name}
                                </Text>
                            </Tooltip>
                            <Button title="Logout" danger style={{ padding: 5 }} href={AAD_LOGOUT} type="link" icon={<Icons.PoweroffOutlined />} />
                        </Space>
                    </Spin>
                </Col>
            </Row>
        </AntdLayout.Header>
    );
};
