import { Alert, AntdList, Avatar, Button, Empty, Icons, Input, Show } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCustomMutation, useGetIdentity } from "@pankod/refine-core";
import { IUser, chatModel } from "interfaces";
import { useState } from "react";
import { API_PATH, OPENAI_LOGO_PATH, RESOURCE_PATH } from "scripts/site";
const { Search } = Input;

export const ProgressiveDataList: React.FC<IResourceComponentsProps> = () => {
  const [chatData, setChatData] = useState<chatModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { mutate } = useCustomMutation();
  const [inputValue, setInputValue] = useState('');

  const apiCall = (userText: string) => {
    if (!userText) {
      return;
    }

    setIsLoading(true);
    // Clear the chat history if there were any errors occured
    if (chatData.some(c => c.error)) {
      chatData.splice(0);
      setChatData(chatData);
    }

    chatData.push({
      actor: "user",
      conversation: {
        contextual: userText,
        standard: userText
      },
      error: false
    });
    setChatData(chatData);
    //setConversationText("");    
    setInputValue('');

    mutate({
      url: API_PATH + "/" + RESOURCE_PATH.PROGRESSIVEDATA,
      method: "post",
      values: {
        chatContext: chatData,
        query: userText
      }
    },
      {
        onSuccess: (data, variables, context) => {
          chatData.push({
            actor: "assistant",
            conversation: {
              contextual: data?.data?.contextual,
              standard: data?.data?.standard,
            },
            error: false
          });
          setChatData(chatData);
          setIsLoading(false);
        },
        onError: (data, variables, context) => {
          //if (data.statusCode === 403) {
          chatData.push({
            actor: "assistant",
            conversation: {
              contextual: data?.statusCode + " - " + data?.response?.statusText + ": " + data?.response?.data?.message,
              standard: data?.statusCode + " - " + data?.response?.statusText + ": " + data?.response?.data?.message,
            },
            error: true
          });
          setChatData(chatData);
          setIsLoading(false);
          //}
        }
      }
    );
  }

  const { data } = useGetIdentity();
  const user = data as IUser;
  const userAlias = user?.name?.split("@")[0] ?? "User";

  return (
    <Show
      canDelete={false}
      headerProps={{
        backIcon: false,
        title: "Progressive Data Extraction",
        extra: chatData.length > 0 && <Button danger icon={<Icons.ClearOutlined />} onClick={() => { setChatData([]) }}>Clear</Button>
      }}>

      <Alert message={
        <>A copilot intelligent enough to ask a series of questions to get all the required information from user to proceed with providing help. Access detailed article
          <a target="_blank" rel="noopener noreferrer" href="https://aka.ms/tcs-helium/#/accelerators/article/show/09ed6f58-adea-4773-92fe-21d0f9564f24"> here.</a>
        </>
      }
        type="info" showIcon={false} banner style={{ marginBottom: 20 }}></Alert>

      <div
        id="scrollableDiv"
        style={{
          maxHeight: 500,
          overflow: 'auto',
          marginBottom: 20,
          //padding: '0 16px',
          //border: '1px solid rgba(140, 140, 140, 0.35)',
        }}
      >
        {chatData.length > 0 ?
          <AntdList
            loading={isLoading}
            itemLayout="vertical"
            pagination={false}
            dataSource={chatData}
            renderItem={(item) => (
              item.actor === "assistant"
                ? <AntdList.Item style={{ border: "none" }}>
                  <AntdList.Item.Meta
                    avatar={<Avatar src={OPENAI_LOGO_PATH} />}
                    title={"GPT-4"}
                    description={<>
                      {
                        item.conversation.standard
                          .split(/(?:\r\n|\r|\n)/g)
                          .map((text, index) => <>{text}<br /></>)
                      }

                    </>} />
                </AntdList.Item>
                : <AntdList.Item style={{ border: "none" }}>
                  <AntdList.Item.Meta
                    avatar={<Avatar src={"user.png"} />}
                    title={userAlias}
                    description={item.conversation.standard} />
                </AntdList.Item>
            )}
          >
          </AntdList>
          : <Empty description="No conversations" image={Empty.PRESENTED_IMAGE_DEFAULT}></Empty>}
      </div>

      <Search
        placeholder="Inquire about your ticket/case status or get help with any process."
        enterButton={<>Send  <Icons.SendOutlined /> </>}
        size="large"
        loading={isLoading}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        required
        onSearch={apiCall}
      />
    </Show>
  );
};