import { Icons } from "@pankod/refine-antd";
import { ResourceProps } from "@pankod/refine-core";
import { About } from "pages/about/about";
import { BrainstormingList } from "pages/brainstorming/list";
import { ChatList } from "pages/chat/list";
import { ChatSquareList } from "pages/chatsquare/list";
import { GenAIIVRSList } from "pages/genAI IVRS/list";
import { HroList } from "pages/hro/list";
import { ProgressiveDataList } from "pages/progressivedata/list";
import { ProjectSummaryList } from "pages/projectSummary/list";
import { ResumeParserList } from "pages/resumeparser/list";
import { SecurityAssessmentList } from "pages/securityCopilot/list";
import { RESOURCE_PATH } from "scripts/site";

export const heliumResources: ResourceProps[] = [

    {
        name: RESOURCE_PATH.HRO,
        key: RESOURCE_PATH.HRO,
        list: HroList,
        options: { label: "HR Operations" },
        icon: <Icons.UserSwitchOutlined />
    },
    {
        name: RESOURCE_PATH.CHATSQUARE,
        key: RESOURCE_PATH.CHATSQUARE,
        list: ChatSquareList,
        options: { label: "Chat²GPT" },
        icon: <Icons.CommentOutlined />
    },
    {
        name: RESOURCE_PATH.BRAINSTORMING,
        key: RESOURCE_PATH.BRAINSTORMING,
        list: BrainstormingList,
        options: { label: "Brainstorming" },
        icon: <Icons.BulbOutlined />
    },
    {
        name: RESOURCE_PATH.PROGRESSIVEDATA,
        key: RESOURCE_PATH.PROGRESSIVEDATA,
        list: ProgressiveDataList,
        options: { label: "Progressive Data" },
        icon: <Icons.SwapOutlined />
    },
    {
        name: RESOURCE_PATH.CHAT,
        key: RESOURCE_PATH.CHAT,
        list: ChatList,
        options: { label: "Chat" },
        icon: <Icons.CommentOutlined />
    },
    {
        name: RESOURCE_PATH.RESUMEPARSER,
        key: RESOURCE_PATH.RESUMEPARSER,
        list: ResumeParserList,
        options: { label: "Resume Parser" },
        icon: <Icons.FilePdfOutlined />
    },
    {
        name: RESOURCE_PATH.SECURITYCOPILT,
        key: RESOURCE_PATH.SECURITYCOPILT,
        list: SecurityAssessmentList,
        options: { label: "Security Co-pilot" },
        icon: <Icons.SafetyOutlined />
    },
    {
        name: RESOURCE_PATH.GENAIIVRS,
        key: RESOURCE_PATH.GENAIIVRS,
        list: GenAIIVRSList,
        options: { label: "IVRS Chat" },
        icon: <Icons.PhoneOutlined /> 
    },
    // {
    //     name: RESOURCE_PATH.IVRSVOICE,
    //     key: RESOURCE_PATH.IVRSVOICE,
    //     list: IVRSVoiceList,
    //     options: { label: "IVRS Voice" },
    //     icon: <Icons.CustomerServiceOutlined />
    // },
    {
        name: RESOURCE_PATH.PROJECTSUMMARY,
        key: RESOURCE_PATH.PROJECTSUMMARY,
        list: ProjectSummaryList,
        options: { label: "Project Synopsis" },
        icon: <Icons.ProjectOutlined />
    },
    {
        name: RESOURCE_PATH.ABOUT,
        key: RESOURCE_PATH.ABOUT,
        list: About,
        canDelete: false,
        options: { label: "About" },
        icon: <Icons.InfoOutlined />
    }
];