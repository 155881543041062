import { Alert, AntdList, Avatar, Button, Divider, Form, Icons, Input, SaveButton, Select, Show, useForm } from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { chatModel } from "interfaces";
import { useState } from "react";
import { OPENAI_LOGO_PATH, OPENAI_LOGO_PATH2 } from "scripts/site";
//const { Search } = Input;

export const HroList: React.FC<IResourceComponentsProps> = () => {
  const [chatData, setChatData] = useState<chatModel[]>([]);

  const { formProps, saveButtonProps, formLoading } = useForm({
    submitOnEnter: true,
    onMutationSuccess(data, variables, context) {
      setChatData(data?.data as chatModel[]);
    },
    onMutationError(error, variables, context) {
      setChatData([{
        actor: "GPT-4",
        conversation: {
          contextual: error?.statusCode + " - " + error?.response?.statusText + ": " + error?.response?.data?.message,
          standard: error?.statusCode + " - " + error?.response?.statusText + ": " + error?.response?.data?.message,
        },
        error: true
      }]);
    },

    redirect: false,
  });


  return (
    <Show
      canDelete={false}
      isLoading={formLoading}
      headerProps={{
        backIcon: false,
        title: "HR Operations",
        extra: chatData.length > 0 && <Button danger icon={<Icons.ClearOutlined />} onClick={() => { setChatData([]) }}>Clear</Button>
      }}>

      {<Alert message={
        <>Simulates conversation between HR officer and an employee of the company on any given topic.
          <a target="_blank" rel="noopener noreferrer" href="https://aka.ms/tcs-helium/#/accelerators/article/show/8e31fed5-0f85-494c-9b4e-2ee9cbc9f897"> here.</a>
        </>
      }
        type="info" showIcon={false} banner style={{ marginBottom: 20 }}></Alert>}
      <Form {...formProps} layout="vertical" autoComplete="off"
        initialValues={{
          characterOption: "3"
        }}>
        <Form.Item label="Topic" name="topic"
          style={{ marginBottom: 40 }}
          rules={[{
            required: true,
            type: 'array',
            message: 'Please select one topic',
            max: 1,
          }]}
        >
          {/* <Input placeholder="A topic to discuss with HR" /> */}
          <Select mode="tags" allowClear
            placeholder="A topic to discuss with HR. Select from list OR type a new one."
            tokenSeparators={[',']}
          >
            <Select.Option value={"There is a anti-bribery policy compliance violation by the employee"} key={"t1"}>
              There is a anti-bribery policy compliance violation by the employee
            </Select.Option>
            <Select.Option value={"Employee is not satisfied with the appraisal rating she got and initiates the discussion"} key={"t2"}>
              Employee is not satisfied with the appraisal rating she got and initiates the discussion
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          hidden
          name="characterOption"
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <SaveButton {...saveButtonProps} title="Send" icon={<Icons.SendOutlined />}>Send</SaveButton>
        </Form.Item>
      </Form>
      {chatData.length > 0 &&
        <>
          <Divider>Conversation</Divider>
          <div
            id="scrollableDiv"
            style={{
              maxHeight: 500,
              overflow: 'auto',
              marginBottom: 20,
              //padding: '0 16px',
              //border: '1px solid rgba(140, 140, 140, 0.35)',
            }}
          >
            <AntdList
              itemLayout="vertical"
              pagination={false}
              dataSource={chatData}
              renderItem={(item, index) => (
                <AntdList.Item style={{ border: "none" }}>
                  <AntdList.Item.Meta
                    avatar={<Avatar src={index % 2 === 0 ? OPENAI_LOGO_PATH : OPENAI_LOGO_PATH2} />}
                    title={item.actor}
                    description={item.conversation.standard} />
                </AntdList.Item>
              )}
            >
            </AntdList>
          </div>
        </>
      }

    </Show>
  );
};