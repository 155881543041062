import { Alert, AntdList, Avatar, Button, Divider, Form, Icons, Input, SaveButton, Show, useForm } from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { chatModel } from "interfaces";
import { useState } from "react";
import { OPENAI_LOGO_PATH, OPENAI_LOGO_PATH2 } from "scripts/site";
//const { Search } = Input;
const { TextArea } = Input;

export const ProjectSummaryList: React.FC<IResourceComponentsProps> = () => {
  const [chatData, setChatData] = useState<chatModel[]>([]);

  const { formProps, saveButtonProps, formLoading } = useForm({
    submitOnEnter: true,
    onMutationSuccess(data, variables, context) {
      setChatData([{
        actor: "GPT-4",
        conversation: {
          contextual: String(data?.data),
          standard: String(data?.data)
        },
        error: false
      }]);
    },
    onMutationError(error, variables, context) {
      setChatData([{
        actor: "GPT-4",
        conversation: {
          contextual: error?.statusCode + " - " + error?.response?.statusText + ": " + error?.response?.data?.message,
          standard: error?.statusCode + " - " + error?.response?.statusText + ": " + error?.response?.data?.message,
        },
        error: true
      }]);
    },

    redirect: false,
  });


  return (
    <Show
      canDelete={false}
      isLoading={formLoading}
      headerProps={{
        backIcon: false,
        title: "Project Synopsis",
        extra: chatData.length > 0 && <Button danger icon={<Icons.ClearOutlined />} onClick={() => { setChatData([]) }}>Clear</Button>
      }}>

      <Alert message={
        <>Generate project summary by compressing the given project description and details in to a standard format complying to both Microsoft and TCS confidentiality regulations
        </>
      }
        type="info" showIcon={false} banner style={{ marginBottom: 20 }}></Alert>
      <Form {...formProps} layout="vertical" autoComplete="off"
        initialValues={{
          characterOption: "2"
        }}>
        <Form.Item label="Project Details" name="topic"
          style={{ marginBottom: 40 }}
          rules={[{
            required: true,
            message: 'Please input project details!',
          }]}
        >
          <TextArea rows={6} placeholder="Project description and details" />
        </Form.Item>
        <Form.Item
          hidden
          name="characterOption"
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <SaveButton {...saveButtonProps} title="Send" icon={<Icons.SendOutlined />}>Send</SaveButton>
        </Form.Item>
      </Form>
      {chatData.length > 0 &&
        <>
          <Divider>Summary</Divider>
          <div
            id="scrollableDiv"
            style={{
              maxHeight: 500,
              overflow: 'auto',
              marginBottom: 20,
              //padding: '0 16px',
              //border: '1px solid rgba(140, 140, 140, 0.35)',
            }}
          >
            <AntdList
              itemLayout="vertical"
              pagination={false}
              dataSource={chatData}
              renderItem={(item, index) => (
                <AntdList.Item style={{ border: "none" }}>
                  <AntdList.Item.Meta
                    avatar={<Avatar src={index % 2 === 0 ? OPENAI_LOGO_PATH : OPENAI_LOGO_PATH2} />}
                    title={item.actor}
                    description={<>
                      {
                        item.conversation.standard
                          .split(/(?:\r\n|\r|\n)/g)
                          .map((text, index) => <>{text}<br /></>)
                      }

                    </>} />
                </AntdList.Item>
              )}
            >
            </AntdList>
          </div>
        </>
      }

    </Show>
  );
};